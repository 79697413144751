import style from "./ModalStyle.module.css";
import chrystal from "../../assets/imgs/chrystall.svg";
import {useTranslation} from "react-i18next";

type ModalType = {
    setOpenWinModal: (tr: boolean) => void
    balanceChanging: number | undefined | null
    link: string | undefined
    takenMultiplier: number
}
export const Modal = (props: ModalType) => {
    console.log(props.link)
    const returnToGame = () => {
        props.setOpenWinModal(false)
    }
    const {t} = useTranslation();


    return (
        <div>
            <div className={style.Modal_wrapper}></div>
            <div className={style.Little_modal_container}>
                <div>
                    <p className={style.Title_little_modal}>
                        {t("youHaveEarned")}!
                    </p>
                    <div>
                        {props.balanceChanging}
                        <img src={chrystal} alt={'chrystall'} className={style.Chrystall_img1}/>
                    </div>
                </div>
                <div className={style.Little_right_block_title}>
                    {props.takenMultiplier}x
                </div>
            </div>
            <div className={style.Modal_container}>
                <button className={style.Modal_btn}>
                    <a href={props.link} target={"_blank"}>{t("takeProfit")}</a>
                </button>
                <button className={style.Modal_btn} onClick={returnToGame}> {t("continue")}</button>
            </div>
        </div>
    )
}