import React from 'react';
import './App.css';
import './assets/style/reset.css'
import {Game} from "./components/game/Game";

import scale from './assets/imgs/scaleHorizontV.svg'
import {MainLogo} from "./components/mainLogo/MainLogo";
function App() {
    return (
        <div className="App">
            <Game/>
        </div>
    );
}

export default App;
