import style from "../gameField/GameFeldStyle.module.css";
import {useEffect, useState} from "react";
import {ValueX} from "../valueX/ValueX";

type ProgressBarType = {
    setRoadMove:(tr:boolean) => void
    setStartFly:(tr:boolean) => void
    disabledBetBtn:boolean
    setProgress:(tr:any) => void
    progress:number
    bet:boolean
    startFly:boolean
    conclusionBetBtn:boolean
    setConclusionBetBtn:(tr:boolean) => void
    setDisabledBetBtn:(tr:boolean) => void
    setDisabledRateBtn:(tr:boolean) => void
    createNewGame:() => void
}

export const ProgressBar = (props:ProgressBarType) => {


    useEffect(() => {
        if(props.progress !== 0){

            setTimeout(() => {
                props.setProgress((tr:any) => tr - 4)
            }, 120)
        }else if(props.progress <= 2){
            if(!props.bet){
                props.setDisabledBetBtn(!props.disabledBetBtn)
            }else if(props.bet){
                props.setConclusionBetBtn(true)
            }
            props.createNewGame()
            props.setDisabledRateBtn(true)
            props.setRoadMove(true)
            props.setStartFly(!props.startFly)

        }
    }, [props.progress, props.setProgress]);


    return(
        <div className={`${style.Loader} ${props.progress <= 2 && style.Loader_none}`}>
            <div className={style.Loader_line} style={{width: `${props.progress}px`}}></div>
        </div>
    )
}