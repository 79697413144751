import style from './MainLogo.module.css';
import circleOutSide from './loader-circle-outside.a62824eff45d18a1e802ae204275dbbc.svg';
import men from './loader-rocket.358c9c720b46827191f5cd947eacbeb0.svg';
import rocket from './loader-fiers.5e63c90c373b37b86e48f3943e9f0e64.svg';
import circleInside from './loader-circle-inside.a4f7704b38b0ae79c2bf59f839777114.svg';


export const MainLogo = () => {
    return(
        <div className={style.Wrapper}>
            <div className={style.LinesBlock}>
                <img src={circleInside} alt="" className={style.circleOutSide}/>
                <img src={circleInside} alt="" className={style.circleInSide}/>
            </div>
            <div className={style.MenBlock}>
                <img src={men} alt="" className={style.Men}/>
                <img src={rocket} alt="" className={style.Fire}/>
            </div>
        </div>
    )
}