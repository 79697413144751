import arrow from '../../assets/imgs/arrow.svg';
import close from '../../assets/imgs/close.svg';
import chrystall from '../../assets/imgs/chrystall.svg';
import style from './History.module.css';
import {useEffect, useState} from "react";
import axios from "axios";
import {GetBalanceType, HistoryType} from "../types/types";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";

type HistoryPropsType = {
    history: boolean
    setHistory: (tr: boolean) => void
}

export const History = (props: HistoryPropsType) => {

    const [history, setHistory] = useState<HistoryType[]>()
    const [historyCount, setHistoryCount] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage] = useState(5);
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get("id")
    const lang = searchParams.get("lang") || "ru"
    const {t} = useTranslation();
    useEffect(() => {
        axios.get<HistoryType[]>(process.env.REACT_APP_API_URL + `/history/${id}`)
            .then(res => {
                setHistory(res.data)
                if(!res.data) return
                setHistoryCount(res.data.length ?? 0)
                setPageCount(Math.ceil(res.data.length / perPage))
            }).catch(rej => {
        })
    }, [perPage, pageCount])

    const handlePageClick = (e: { selected: number }) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage + 1)
    };
    return (
        <div className={style.Wrapper}>
            <div className={style.Container}>
                <div className={style.Header}>
                    <p className={style.Header_title}>{t("bettingHistory")}</p>
                    <img src={close} alt="" className={style.Close_img}
                         onClick={() => props.setHistory(!props.history)}/>
                </div>
                <div className={style.Wrapper_bid}>
                    {history?.slice((currentPage - 1) * perPage, currentPage * perPage).map((story: HistoryType) => {

                        const dateObject = new Date(story.ts * 1000)
                        const date = dateObject.toLocaleDateString("ru-Ru")
                        const time = dateObject.getHours() + ':' + dateObject.getMinutes()


                        return (
                            <div className={style.Bid_block}>
                                <div>
                                    <div className={style.Time}>{time}</div>
                                    <div className={style.Date}>{date}</div>
                                </div>
                                <div className={style.Value}>{story.balance_change.toFixed(2)}
                                    <img src={chrystall} alt="" className={style.Chrystall}/>
                                </div>
                            </div>)
                    })

                    }

                </div>
            </div>
            <div className={style.FooterWrapper}>
                <ReactPaginate pageCount={pageCount}
                               breakLabel=""
                               marginPagesDisplayed={pageCount}
                               pageRangeDisplayed={1}
                               className={style.Footer}
                               pageClassName={style.PageClaassName}
                               activeClassName={style.ActiveClassName}
                               nextClassName={style.NextClassName}
                               onPageChange={handlePageClick}
                               previousLinkClassName={style.PreviousClassName}
                               previousLabel={<img src={arrow} alt="" className={style.Arrow_left}/>}
                               nextLabel={<img src={arrow} alt="" className={style.Arrow_right}/>}
                />
            </div>
        </div>
    )
}