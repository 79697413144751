import style from "./GameFeldStyle.module.css";
import stars from '../../assets/imgs/starluck.svg';
import scaleVertical from "../../assets/imgs/scaleHorizontV.svg";
import spacemen from "../../assets/imgs/spacemen.svg";
import men from "../../assets/imgs/men.svg";
import line from "../../assets/imgs/line.png";
import shadow from "../../assets/imgs/shadow.png";
import scaleHorizont from "../../assets/imgs/scaleHorizont.svg";
import {ProgressBar} from "../progressBar/ProgressBar";

import {ValueX} from "../valueX/ValueX";
import {CreateGameType} from "../types/types";
import {useTranslation} from "react-i18next";
import {MainLogo} from "../mainLogo/MainLogo";

type GameFieldType = {
    disabledBetBtn: boolean
    conclusionBetBtn: boolean
    bet: boolean
    setDisabledBetBtn: (tr: boolean) => void
    setConclusionBetBtn: (tr: boolean) => void
    setDisabledRateBtn: (tr: boolean) => void
    setStopGame: (tr: boolean) => void
    setBetValue: (tr: number) => void
    setBet: (tr: boolean) => void
    setOpenWinModal: (tr: boolean) => void
    balanceChanging: number | undefined | null
    setBalanceChanging: (tr: number | undefined | null) => void
    betValue: number
    addBalance: (multiplier: number, balance_change: number | undefined) => void
    stopGame: boolean
    openWinModal: boolean
    setBalance: (tr: number) => void
    balance: number | undefined
    countdown_start: (newGameData: CreateGameType) => void
    roadMove: boolean
    setRoadMove: (tr: boolean) => void
    startFly: boolean
    progress: number
    flyMen:boolean
    setProgress: (tr: number) => void
    setStartFly: (tr: boolean) => void
    valueX:string
    createNewGame:() => void

}
export const GameField = (props: GameFieldType) => {

    const {t} = useTranslation();

    return (
        <div className={style.Wrapper}>
            <div  className={style.Stars_img}/>
            <div className={style.Main_content_block}>
                <div className={style.Main_left_line}></div>
                <div className={style.Container_for_vertical}>
                    <div className={`${style.Wr_vert} ${props.roadMove && style.Wr_vertMoving}`}>
                        <img src={scaleVertical} className={style.Vertical}/>
                    </div>
                </div>
                <div className={style.Main_content}>
                    {
                        !props.startFly && <div className={style.Main_inner_content}>
                            <MainLogo/>
                            <h1 className={style.Title_main}> {t("waiting")}</h1>
                            <ProgressBar setRoadMove={props.setRoadMove}
                                         bet={props.bet}
                                         setStartFly={props.setStartFly}
                                         progress={props.progress}
                                         startFly={props.startFly}
                                         setProgress={props.setProgress}
                                         disabledBetBtn={props.disabledBetBtn}
                                         setDisabledBetBtn={props.setDisabledBetBtn}
                                         conclusionBetBtn={props.conclusionBetBtn}
                                         setDisabledRateBtn={props.setDisabledRateBtn}
                                         setConclusionBetBtn={props.setConclusionBetBtn}
                                // countdown_start={countdown_start}
                                         createNewGame={props.createNewGame}
                            />
                        </div>
                    }


                    {
                        props.startFly &&
                        <div className={style.Main_inner_content2}>
                            <div className={`${style.Men_block} ${props.flyMen && style.Men_fly}`}>
                                <img src={men} alt=""/>
                            </div>
                            <img src={line} alt="" className={` ${style.Line_curved} ${props.flyMen && style.Line_curvedStop }`}/>
                            <img src={shadow} alt="" className={`${style.Shadow_line} ${props.flyMen && style.Line_curvedStop}`}/>
                        </div>
                    }
                    {
                        props.valueX && <ValueX valueX={props.valueX}/>
                    }


                    <div className={style.Main_bottom_line}></div>
                    <div className={style.Container_for_horisont}>
                        <div className={`${style.Wr_for_horisont} ${props.roadMove && style.Wr_for_horisontMoving}`}>
                            <img src={scaleHorizont} alt="" className={style.Horisont}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}