import style from './GameStyle.module.css';
import crystal from '../../assets/imgs/chrystall.svg';
import wallet from "../../assets/imgs/wallet.svg";
import logo from '../../assets/imgs/logo.svg';
import {GameField} from "../gameField/GameField";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../modal/Modal";
import axios from "axios";
import {CreateGameType, GetBalanceType} from "../types/types";
import {History} from "../history/History";
import {useTranslation} from "react-i18next";


export const Game = () => {

    const [bet, setBet] = useState(false)
    const [disabledBetBtn, setDisabledBetBtn] = useState(false)
    const [disabledRateBtn, setDisabledRateBtn] = useState(false)
    const [conclusionBetBtn, setConclusionBetBtn] = useState(false)
    const [betValue, setBetValue] = useState(10)
    const [openWinModal, setOpenWinModal] = useState(false)
    const [data, setData] = useState<GetBalanceType>()
    const [balanceChanging, setBalanceChanging] = useState<any>()
    const [stopGame, setStopGame] = useState(false)
    const [balance, setBalance] = useState<number>(0)
    const [progress, setProgress] = useState(264);
    const [roadMove, setRoadMove] = useState(false)
    const [startFly, setStartFly] = useState(false)
    const [newGameData, setNewGameData] = useState<CreateGameType>()
    const [valueX, setValueX] = useState('')
    const [flyMen, setFlyMen] = useState(false)
    const [history, setHistory] = useState(false)
    const [takenMultiplier, setTakenMultiplier] = useState<number >(0)
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get("id")
    const lang = searchParams.get("lang") || "ru"

    const takenRef = useRef(false)

    const {t, i18n} = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    useEffect(() => {
        axios.get<GetBalanceType>(process.env.REACT_APP_API_URL + `/get_balance/${id}`)
            .then(res => {
                // setData({...res.data, history: res.data.history.map(({multiplier})=>({multiplier, id: v4()}))})
                setData(res.data)

                if (res.data.balance) {
                    setBalance(res.data.balance)
                }
            }).catch(rej => {
            // console.log('catch get balance')
        })
    }, [stopGame])

    const intervalId = useRef<NodeJS.Timer | null>(null)
    let time = 0;


    function countdown_start(newGameData: CreateGameType) {
        if (intervalId.current && stopGame) {
            clearInterval(intervalId.current)
        }
        if (balance && bet)
            setBalance(balance - betValue)
        intervalId.current = setInterval(() => {
                if (!newGameData)
                    return
                let current_x = 0;
                const target_x = newGameData.multiplier;
                const variable_coeff1 = newGameData.first_coefficient;
                const variable_coeff2 = newGameData.second_coefficient;
                current_x = 1 + (target_x * time) / (variable_coeff1 + variable_coeff2 * Math.sqrt(target_x))

                setValueX(current_x.toFixed(2))


                if (bet && !takenRef.current) {
                    const outPutValue = +(current_x * betValue).toFixed(1)
                    setBalanceChanging(outPutValue)
                }
                if (+current_x.toFixed(2) >= target_x) {
                    intervalId.current && clearInterval(intervalId.current)
                    intervalId.current = null
                    setFlyMen(true)
                    if (bet && !takenRef.current) {
                        addBalance(0, -betValue)
                    }
                    setStopGame(true)
                    return;
                }
                time += 0.1
            }
            ,  100)
    }

    useEffect(() => {
        if (stopGame) {
            intervalId.current && clearInterval(intervalId.current)
            intervalId.current = null
            setRoadMove(false)

            setTimeout(() => {
                takenRef.current = false
                setStartFly(false)
                setProgress(264)
                setStopGame(false)
                setValueX('')
                setFlyMen(false)
                setBet(false)
                setDisabledBetBtn(false)
                setDisabledRateBtn(false)
                setBetValue(10)
                setConclusionBetBtn(false)
            }, 4000)
        }

    }, [stopGame, setStopGame])


    const createNewGame = () => {
        axios.get(process.env.REACT_APP_API_URL + `/new_game/${id}`)
            .then(res => {
                setNewGameData(res.data)
                countdown_start(res.data)
            }).catch(rej => {

        })
    }
    const addBalance = (multiplier: number, balance_change: number | undefined | null) => {
        axios.post(process.env.REACT_APP_API_URL + `/add_balance`, {
            user_id: id,
            balance_change: balance_change,
            multiplier: multiplier
        }).then(res => {
            // console.log(res.data)
        })
    }

    const makeBet = () => {
        if(!conclusionBetBtn){
            setBet(!bet)
        }
        if (conclusionBetBtn && bet && !stopGame) {
            setOpenWinModal(true)
            const difference = balanceChanging - betValue
            setTakenMultiplier(+valueX)
            takenRef.current = true
            addBalance(+valueX, difference)
            setDisabledBetBtn(true)
            if (balanceChanging)
                setBalance(balance + balanceChanging)
        }

    }

    let b = 0
    if (data?.balance) {
        b = data.balance
    }
    const addRate = (idBtn: string) => {
        switch (idBtn) {
            case '10':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(val => val + 10)
                }
                return
            case '-10':
                if (betValue < 10) setDisabledBetBtn(true)
                if (betValue >= 10 && betValue < b) {
                    return setBetValue(val => val - 10)
                }
                return
            case '50':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(val => val + 50)
                }
                return
            case '100':
                if (betValue + 100 <= b) {
                    setDisabledBetBtn(false)
                    return setBetValue(val => val + 100)
                }
                return
            case '250':
                if (betValue < b) {
                    setDisabledBetBtn(false)
                    return setBetValue(val => val + 250)
                }
                return
            case 'max':
                if (betValue !== balance) {
                    setDisabledBetBtn(false)
                    return setBetValue(balance)
                }
                return
        }
    }

    return (
        <>
            {history ? <History setHistory={setHistory} history={history}/>
                : <div className={style.Wrapper}>
                    {openWinModal && <Modal setOpenWinModal={setOpenWinModal}
                                            takenMultiplier={takenMultiplier}
                                            balanceChanging={balanceChanging}
                                            link={data?.ref_link}/>}
                    <header>
                        <div className={style.HeaderTopBlock}>
                            <img src={logo} alt="" className={style.logo_jet}/>
                            <div className={style.HeaderBtnsBlock}>
                                <button className={style.HeaderWalletBtn} onClick={() => setHistory(!history)}>
                                    <img src={wallet} alt="" className={style.WalletImg}/>
                                    {Math.round(balance)}
                                    <img src={crystal} alt="" className={style.ChrystallImg}/>
                                </button>
                                <div className={style.HeaderSeparator}></div>
                                <div className={style.HeaderRecordBlock}>
                                    <p className={style.DescRecord}>{t("record")}</p>
                                    <span className={style.HeaderRecordCount}>
                                    {data?.record}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className={style.HeaderBottomBlock}>
                            {
                                data?.history.map((val, i) => {
                                    const multiplier = +val.multiplier.toFixed(1)

                                    return <button
                                        key={`${multiplier}${i}`}
                                        className={`${style.Inc} ${multiplier <= 2 && style.IncBlue} 
                                            ${multiplier > 2 && multiplier <= 9 && style.IncPurple}
                                            ${multiplier > 9 && multiplier <= 200 && style.IncOrange}`}>
                                        {multiplier}x
                                    </button>
                                })
                            }
                        </div>
                    </header>
                    <GameField disabledBetBtn={disabledBetBtn} bet={bet}
                               setDisabledBetBtn={setDisabledBetBtn}
                               conclusionBetBtn={conclusionBetBtn}
                               setDisabledRateBtn={setDisabledRateBtn}
                               setConclusionBetBtn={setConclusionBetBtn}
                               setBalanceChanging={setBalanceChanging}
                               balanceChanging={balanceChanging}
                               betValue={betValue}
                               addBalance={addBalance}
                               stopGame={stopGame}
                               setStopGame={setStopGame}
                               setBet={setBet}
                               setBetValue={setBetValue}
                               setBalance={setBalance}
                               balance={balance}
                               setOpenWinModal={setOpenWinModal}
                               openWinModal={openWinModal}
                               countdown_start={countdown_start}
                               roadMove={roadMove}
                               setRoadMove={setRoadMove}
                               startFly={startFly}
                               setStartFly={setStartFly}
                               progress={progress}
                               setProgress={setProgress}
                               valueX={valueX}
                               flyMen={flyMen}
                               createNewGame={createNewGame}
                    />
                    <div className={style.Rate_block}>
                        <div className={style.Rate_counter_block}>
                            <div className={style.Rate_counter_top}>
                                <button className={style.Btn_dec}
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('-10')}>-
                                </button>
                                <div className={style.Count_value_block}>
                                     <span className={style.Count_value}>
                                         {Math.round(betValue)}
                                     </span>
                                    <img src={crystal} alt="" className={style.Chrystall_img}/>
                                </div>
                                <button className={style.Btn_inc}
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('10')}>+
                                </button>
                            </div>
                            <div className={style.Rate_counter_bottom}>
                                <button className={style.Btn_add_rate} id="btn50"
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('50')}>+50
                                </button>
                                <button className={style.Btn_add_rate} id="btn100"
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('100')}>+100
                                </button>
                                <button className={style.Btn_add_rate} id="btn250"
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('250')}>+250
                                </button>
                                <button className={style.Btn_add_rate} id="max"
                                        disabled={bet || disabledRateBtn}
                                        onClick={() => addRate('max')}>MAX
                                </button>
                            </div>
                        </div>
                        <div className={style.Rate_block_btn_wrapper}>
                            <button className={`${style.Rate_block_btn} 
                                         ${bet && style.Rate_block_btn_cancelled} 
                                         ${conclusionBetBtn && style.Rate_block_btn_conclusion}
                                         ${(stopGame || disabledBetBtn) && style.DisabledBtn}`}
                                    disabled={disabledBetBtn}
                                    onClick={makeBet}>
                                {
                                    bet && conclusionBetBtn  &&
                                    <span className={style.DescriptionBalance}>
                                         {balanceChanging}
                                        <img src={crystal} alt="" className={style.Chrystall_img1}/>
                                     </span>
                                }
                                {bet && !conclusionBetBtn && <span>{t("cancel")}</span>}
                                {!bet  && <span>{t("bid")}</span>}
                                {(bet && conclusionBetBtn ) && <span>{t("take")}</span>}
                            </button>
                        </div>
                    </div>
                    <a href={data?.ref_link} className={style.Take_win_btn} target={'_blank'}>
                        {t("takeProfit")}
                    </a>
                </div>
            }
        </>

    )
}