import style from './ValueX.module.css';
import {useState} from "react";

type ValueXType = {
    valueX:string
}

export const ValueX = (props:ValueXType) => {


    return(
        <div className={style.Current_value_x}>
            <p className={style.Value_x}>x{props.valueX}</p>
        </div>
    )
}